import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import SEO from '../../../components/SEO';
import Body from '../../../components/layout/Body';
import ResponsiveContainer from '../../../components/layout/ResponsiveContainer';

import ContactForm from '../../../components/forms/ContactForm';

import HeroH2 from '../../../components/hero/HeroH2';

import { ViewportBreakpoints } from '../../../components/cssConstants';

import H3 from '@rotaready/frecl/build/H3';
import FlexContainer from '@rotaready/frecl/build/FlexContainer';
import Text from '@rotaready/frecl/build/Text';
import Pill from '@rotaready/frecl/build/Pill';
import Button from '@rotaready/frecl/build/Button';
import Margin from '@rotaready/frecl/build/Margin';
import OL from '@rotaready/frecl/build/OL';
import TextListItem from '@rotaready/frecl/build/TextListItem';

const isBrowser = typeof window !== 'undefined';

const HeroWrapper = styled.div`
  background-repeat: no-repeat;

  background-position: center bottom;
  background-size: 100% auto;
  background-image: url(${props => props.backgroundMobile});

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    background-position: right top;
    background-size: auto 100%;
    background-image: url(${props => props.backgroundDesktop});
    transform: translate3d(0, -80px, 0);
  }
`;

const Hero = styled.div`
  padding: 80px 0 470px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 210px 0 130px 0;
  }
`;

const HeroInnerContainer = styled(FlexContainer)`
  flex-direction: column;
  width: 100%;
  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
    text-align: left;
  }
`;

const HeroBody = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    width: 40%;
  }
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const BodyItem = styled.div`
  flex: 1 1 auto;
  margin-top: 40px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex: 0 1 45%;
    margin: 0;
  }
`;

const TextBlock = styled.div`
  margin-bottom: 20px;
`;

const TileTagContainer = styled.div`
  margin-bottom: 20px;

  > div {
    display: inline-block;
    margin-right: 10px;
  }
`;

const TileTag = styled(Pill).attrs({
  size: 'sm',
  uistyle: 'primary',
})``;

const BenefitBlock = styled.div`
  padding: 40px;
  border-radius: 5px;
  background-color: ${({ theme: { colors } }) => colors.brand10};
`;

const header = {
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  invertColors: false,
};

class Page extends React.Component {
  constructor(props) {
    super(props);

    this.formTopRef = React.createRef();
  }

  goToForm = () => {
    const scrollTo = this.formTopRef.current.offsetTop;

    if (isBrowser) {
      window.scroll({ top: scrollTo, left: 0, behavior: 'smooth' });
    }
  };

  render() {
    const {
      data,
    } = this.props;

    return (
      <Body header={header}>
        <SEO
          title="The trends and tech changing the face of hospitality - Rotaready"
          description="From Instagram influencers and rise of the plant based diet, to trends in tech and customer experience; here we discuss the changing face of hospitality."
          url="resources/ebook-trends-tech-changing-face-hospitality"
        />

        <HeroWrapper
          backgroundDesktop={data.heroBackgroundDesktop.childImageSharp.fixed.src}
          backgroundMobile={data.heroBackgroundMobile.childImageSharp.fixed.src}
        >
          <ResponsiveContainer>
            <Hero>
              <HeroInnerContainer>
                <HeroBody>
                  <TileTagContainer>
                    <TileTag text="Hospitality" />
                  </TileTagContainer>

                  <HeroH2 color="brand160" text="The trends and tech changing the face of hospitality" />

                  <Margin marginTop={40}>
                    <div>
                      <Button uistyle="primary" text="Download now" size="lg" onClick={this.goToForm} />
                    </div>
                  </Margin>
                </HeroBody>
              </HeroInnerContainer>
            </Hero>
          </ResponsiveContainer>
        </HeroWrapper>

        <ResponsiveContainer>
          <BodyWrapper>
            <BodyItem>
              <TextBlock>
                <H3 uistyle="brand160" text="As we enter a new decade, what better time is there to reflect on the trends and tech changing the face of hospitality." />
              </TextBlock>

              <TextBlock>
                <Text size="xl" text="From Instagram influencers and rise of the plant based diet, to trends in tech and the importance of customer experience, we'll take a look at how these developments are changing the face of the industry. In this eBook we'll cover:" />
              </TextBlock>

              <BenefitBlock>
                <TextBlock>
                  <OL size="lg" uistyle="normal" iconUistyle="primary">
                    <TextListItem text="The importance of customer experience" />
                    <TextListItem text="Instagram influencers" />
                    <TextListItem text="Advancement of technology" />
                    <TextListItem text="Rise of the plant based diet" />
                    <TextListItem text="Use of delivery services" />
                    <TextListItem text="Low and no alcohol drinks" />
                  </OL>
                </TextBlock>
              </BenefitBlock>
            </BodyItem>

            <BodyItem innerRef={this.formTopRef}>
              <ContactForm
                title=""
                submitLabel="Download now"
                source="resources_ebook_trends_tech_hospitality"
                label="rotaready.com/resources/ebook-trends-tech-changing-face-hospitality"
                postTo="marketing"
                checkboxes={[
                  {
                    id: 'marketing',
                    text: 'I\'d like to receive updates, offers and new content from Rotaready by email. You can unsubscribe at any time.',
                    required: false,
                    initial: false,
                  },
                ]}
                onSuccess={(
                  <>
                    <TextBlock>
                      <H3 uistyle="brand160" text="Happy reading 📚" />
                    </TextBlock>

                    <TextBlock>
                      <Text size="lg" text="Thank you for downloading our eBook on 'The trends and tech changing the face of hospitality'." />
                    </TextBlock>

                    <TextBlock>
                      <a href="/files-static/Rotaready-Ebook-Tech-Trends-Hospitality.pdf" download>
                        <Button uistyle="primary" text="View eBook" size="md" />
                      </a>
                    </TextBlock>
                  </>
                )}
              />
            </BodyItem>
          </BodyWrapper>
        </ResponsiveContainer>
      </Body>
    );
  }
}

export default Page;

export const query = graphql`
  query {
    heroBackgroundDesktop: file(relativePath: { eq: "resources/ebook-trends-tech-changing-face-hospitality/hero-desktop.png" }) {
      childImageSharp {
        fixed(height: 1400, width: 2132, quality: 30) {
          src
        }
      }
    }

    heroBackgroundMobile: file(relativePath: { eq: "resources/ebook-trends-tech-changing-face-hospitality/hero-mobile.png" }) {
      childImageSharp {
        fixed(height: 896, width: 750, quality: 30) {
          src
        }
      }
    }
  }
`;
